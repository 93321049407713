import {Link} from "react-router-dom";
import React, {useState} from "react";

export default function Menu(){
    const [ visible , setVisible ] = useState(false);

    const toggleMenu = (e) => {
        visible ? setVisible(false) : setVisible(true)
    }

    return <>
            <div className="menu-icon-wrapper">
                <div onClick={toggleMenu} className={`menu-icon ${ visible ? 'menu-icon-active' : '' }`}>
                </div>
            </div>
            <div className={`menu-wrapper ${visible ? 'visible' : '' }` }>
            <ul className="menu-primary">
                <li>
                    <Link onClick={toggleMenu}  to="/" >Inscription</Link>
                </li>
                <li>
                    <Link onClick={toggleMenu} to="/politique-de-confidentialite" >Politique de confidentialité</Link>
                </li>
                <li>
                    <Link onClick={toggleMenu}  to="/condition-dutilisation" >Condition d'utilisation</Link>
                </li>
            </ul>
        </div>
    </>
}