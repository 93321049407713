const Sexe = [
        {
            id : 'MALE',
            name : 'Homme',
        },
        {
            id : 'FEMALE',
            name : 'Femme',
        }
    ];

export default Sexe;