import React, {useEffect, useRef, useState} from "react";
import { Formik, Form, Field } from 'formik';
import ImagePreview from "./ImagePreview";
import {FormSchema , initialValues} from "./FormValidationSchema";
import cities from "./Cities";
import Sex from "./Sexe";
import axios from "axios";
import { Store } from 'react-notifications-component';
import Availiblelabels from "./labels";
function Formula () {

    const [lang , setLang] = useState('fr')
    const [labels , setLabels] = useState(Availiblelabels['fr'])

    const photoIdentityRef = useRef(null)
    const photoCakeOneRef = useRef(null)
    const photoCakeTwoRef = useRef(null)
    const photoCakeThreeRef = useRef(null)

    useEffect(() => {
        setLabels( Availiblelabels[lang] )
    },[lang])

    const HandleImagePreviewClick = (ref) => {
        ref.current.click()
    }

    const HandleFileInputOnChange = (e , key, setFieldValue ) => {
        setFieldValue( key , e.currentTarget.files[0])
    }

    const showSuccessNotification = ( type , message ) => {
        Store.addNotification({
            title: type === 'success' ? "Merci!" : 'Oops!',
            message: message,
            type: type,
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        });
    }

    const handleSwitchLang = (e , l) => {
        e.preventDefault();
        setLang(l)
    }
    const onSubmit =  ( values , actions ) => {
        actions.setSubmitting(true)

        let formData = new FormData();
        for( let index in values ) {
            if (values.hasOwnProperty(index)) {
                formData.append(index ,values[index])
            }
        }
        axios.post(process.env.REACT_APP_CREATE_CANDIDAT_ENDPOINT , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            showSuccessNotification( 'success' , 'Votre inscription a bien été enregistrée')
            actions.setSubmitting(false)
            actions.resetForm()
        }).catch(function (error) {

            if (typeof error.response.data.message === 'undefined' || error.response.data.message === null) {
                console.log(error)
                return;
            }

            showSuccessNotification( 'danger' , error.response.data.message )
            actions.setSubmitting(false)
        });

    }

    return <div className="form-container boxed">

            <h2 className="page-title">
                {labels.formTitle}
            </h2>

                <div className="lang">
                    <ul className='lang-switcher'>
                        <li>
                            <button className={ lang === 'fr' ? 'active' : null } onClick={(e) => handleSwitchLang(e, 'fr')  } >
                                Français
                            </button>
                        </li>
                       <li>
                           <button className={ lang === 'ar' ? 'active' : null } onClick={(e) => handleSwitchLang(e, 'ar')  } >
                               عربي
                           </button>
                       </li>
                    </ul>
                </div>

        <Formik initialValues={initialValues}
            validationSchema={FormSchema}
            onSubmit={onSubmit}
        >
            {(
                {  errors,
                  touched,
                  setFieldValue , values ,
                  isSubmitting}
            ) => (

                <Form  dir={ lang === 'ar' ? 'rtl' : 'ltr' } autoComplete="off" className="form-styled">
                    <div className="row">
                        <div className="frm-group">
                            <label htmlFor="">{ labels.name }</label>
                            <Field  className={'input'} name="name"/>
                            {errors.name && touched.name ? (
                                <div className={'with-error'}>{errors.name}</div>
                            ) : null}
                        </div>

                        <div className="frm-group">
                            <label htmlFor="">{ labels.birthday }</label>
                            <Field  type="date" className={'input'} name="birthday"  />
                            {errors.birthday && touched.birthday ? errors.birthday : null}
                        </div>

                    </div>


                    <div className="row">

                        <div className="frm-group">
                            <label htmlFor="">
                                { labels.sexe }
                            </label>


                            <Field className="input" as="select" name="sexe">
                                <option value="*">{labels.select}</option>
                                {
                                    Sex?.map(({id,name} ) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))
                                }

                            </Field>

                            {errors.sexe && touched.sexe ? (
                                <div className={'with-error'}>{errors.sexe}</div>
                            ) : null}
                        </div>

                        <div className="frm-group">
                            <label htmlFor="">{ labels.familySituation }</label>
                            <Field  className={'input'} name="familySituation"/>
                            {errors.familySituation && touched.familySituation ? (
                                <div className={'with-error'}>{errors.familySituation}</div>
                            ) : null}
                        </div>

                    </div>


                    <div className="row">
                        <div className="frm-group">
                            <label htmlFor="">{ labels.email }</label>
                            <Field className={'input'} name="email" type="email"/>
                            {errors.email && touched.email ? <div className={'with-error'} >{errors.email}</div> : null}
                        </div>

                        <div className="frm-group">
                            <label htmlFor="">{ labels.phone }</label>
                            <Field  className={'input'} name="phone"/>
                            {errors.phone && touched.phone ? (
                                <div className={'with-error'}>{errors.phone}</div>
                            ) : null}
                        </div>
                    </div>

                    <div  className="row">
                        <div className="frm-group">
                            <label htmlFor="">{ labels.address }</label>
                            <Field  className={'input'} name="address"/>
                            {errors.address && touched.address ? (
                                <div className={'with-error'}>{errors.address}</div>
                            ) : null}
                        </div>

                        <div className="frm-group">
                            <label htmlFor="">{ labels.city }</label>
                            <Field className="input" as="select" name="city">

                                <option value="">{labels.select}</option>
                               {
                                    cities?.map(({id,name} ) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))
                                }

                            </Field>

                            {errors.city && touched.city ? (
                                <div className={'with-error'}>{errors.city}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="frm-group">
                            <label htmlFor="">{ labels.cookingTime }</label>
                            <Field as="textarea" className={'input'} name="cookingTime"/>
                            {errors.cookingTime && touched.cookingTime ? (
                                <div className={'with-error'}>{errors.cookingTime}</div>
                            ) : null}
                        </div>
                        <div className="frm-group">
                            <label htmlFor="">{ labels.frequency }</label>
                            <Field as="textarea" className={'input'} name="frequency"/>
                            {errors.frequency && touched.frequency ? (
                                <div className={'with-error'}>{errors.frequency}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="frm-group">
                            <label htmlFor="">{ labels.speciality }
                            </label>
                            <Field as="textarea" className={'input'} name="speciality"/>
                            {errors.speciality && touched.speciality ? (
                                <div className={'with-error'}>{errors.speciality}</div>
                            ) : null}
                        </div>

                        <div className="frm-group">
                            <label htmlFor="">{ labels.whyParticipate }
                                { lang === 'fr' && <> <br/>&nbsp; </> }
                            </label>

                            <Field as="textarea" className={'input'} name="whyParticipate"/>
                            {errors.whyParticipate && touched.whyParticipate ? (
                                <div className={'with-error'}>{errors.whyParticipate}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="frm-group">
                            <label htmlFor="">
                                { labels.participatedYet }
                            </label>

                            <Field className="input" as="select" name="participatedYet">
                                <option value="">{labels.select}</option>
                                <option value="1">Oui</option>
                                <option value="0">Non</option>
                            </Field>

                            {errors.participatedYet && touched.participatedYet ? (
                                <div className={'with-error'}>{errors.participatedYet}</div>
                            ) : null}
                        </div>

                        <div className="frm-group">
                            { values.participatedYet === "1" &&

                                <>
                                <label htmlFor="">{labels.competitionName}
                                </label>
                                <Field className={'input'} name="competitionName"/>

                                </>
                            }
                                { errors.competitionName && touched.competitionName ? (
                                <div className={'with-error'}>{errors.competitionName}</div>
                            ) : null}
                        </div>
                    </div>


                    <div className="row mb-40">
                        <div className="frm-group">
                            <label>
                                { labels.photoIdentity }
                            </label>
                            <div className="preview-container" onClick={() => { HandleImagePreviewClick( photoIdentityRef) }}>
                                <input name='photoIdentity'
                                       accept="image/png, image/jpeg,image/jpg"
                                       ref={photoIdentityRef}
                                       hidden
                                       onChange={(e) => {
                                    HandleFileInputOnChange(e, 'photoIdentity', setFieldValue)
                                }} className={'input'}  type="file"/>
                                { values.photoIdentity && !errors.photoIdentity && <ImagePreview file={values.photoIdentity} /> }

                            </div>
                            { (errors.photoIdentity ) && <div className='with-error'> {errors.photoIdentity}  </div>}

                        </div>

                        <div className="frm-group">
                            <label>
                                { labels.photoCakeOne }
                            </label>
                            <div className="preview-container" onClick={() => { HandleImagePreviewClick( photoCakeOneRef) }} >

                                <input ref={photoCakeOneRef}
                                       hidden
                                       accept="image/png, image/jpeg,image/jpg"
                                       onChange={(e) => {
                                           HandleFileInputOnChange(e, 'photoCakeOne', setFieldValue)
                                       }}
                                       className={'input'}  type="file"
                                />
                                { values.photoCakeOne && !errors.photoCakeOne &&  <ImagePreview file={values.photoCakeOne} /> }
                            </div>
                            { (errors.photoCakeOne ) && <div className='with-error'> {errors.photoCakeOne}  </div>}

                        </div>

                        <div className="frm-group">
                            <label>
                                { labels.photoCakeTwo }
                            </label>
                            <div className="preview-container" onClick={() => { HandleImagePreviewClick( photoCakeTwoRef) }} >

                                <input ref={photoCakeTwoRef}
                                       hidden
                                       accept="image/png, image/jpeg,image/jpg"
                                       onChange={(e) => {
                                           HandleFileInputOnChange(e, 'photoCakeTwo', setFieldValue)
                                       }}
                                       className={'input'}  type="file"
                                />
                                { values.photoCakeTwo && !errors.photoCakeTwo &&  <ImagePreview file={values.photoCakeTwo} /> }
                            </div>
                            { (errors.photoCakeTwo ) && <div className='with-error'> {errors.photoCakeTwo}  </div>}

                        </div>

                        <div className="frm-group">
                            <label>
                                { labels.photoCakeThree }
                            </label>
                            <div className="preview-container" onClick={() => { HandleImagePreviewClick( photoCakeThreeRef) }} >

                                <input ref={photoCakeThreeRef}
                                       hidden
                                       accept="image/png, image/jpeg,image/jpg"
                                       onChange={(e) => {
                                           HandleFileInputOnChange(e, 'photoCakeThree', setFieldValue)
                                       }}
                                       className={'input'}  type="file"
                                />
                                { values.photoCakeThree && !errors.photoCakeThree &&  <ImagePreview file={values.photoCakeThree} /> }

                            </div>
                            { (errors.photoCakeThree ) && <div className='with-error'> {errors.photoCakeThree}  </div>}

                        </div>
                    </div>


                    <div className="row flex-direction-column" >
                        <div className="frm-group center-inline">
                            <button
                                className="btn-flat"
                                disabled={isSubmitting || Object.keys(errors).length !== 0 }
                                type="submit">
                                ENVOYER
                            </button>

                            {isSubmitting &&  <svg className="spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
                            </svg>
                            }



                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    </div>
}

export default Formula
