import {Component} from "react";
import {motion} from "framer-motion";

class AnimatedPage extends Component{

    constructor(props) {
        super(props);
        this.anaimations = {
            initial : {opacity : 0, x: 100},
            animate : {opacity : 1, x: 0},
            exit : {opacity : 0, x: -100}
        }
    }

    render() {
        return <motion.div variants={this.anaimations}
                           initial='initial'
                           animate="animate"
                           exit="exit" transition={{ duration : 0.4 }}>
                            {this.props.children}
        </motion.div>
    }
}

export default AnimatedPage;