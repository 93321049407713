import {Component} from "react";
import CircleIndicator from "./animations/CircleIndicator";
import AnimatedPage from "./animations/AnimatedPage";
import ConditionsText from "./inc/ConditionsText";

class Condition extends Component{
    render() {
        return <AnimatedPage>
            <CircleIndicator>
                <div className="boxed">
                    <div className="tiny">
                        <h2 className="page-title">
                            Termes et conditions
                        </h2>
                        <div className="tiny">
                            <ConditionsText />
                        </div>
                    </div>
                </div>

            </CircleIndicator>
        </AnimatedPage>
    }
}

export default Condition;