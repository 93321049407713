import {Component} from "react";
import AnimatedPage from "./animations/AnimatedPage";
import CircleIndicator from "./animations/CircleIndicator";
import PoliticText from "./inc/politicText";

class Politic extends Component{
    render() {
        return <AnimatedPage>
            <CircleIndicator>
                <div className="boxed">
                    <div className="tiny">
                        <PoliticText />
                    </div>
                </div>

            </CircleIndicator>
        </AnimatedPage>
    }
}

export default Politic;