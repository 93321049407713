import './styles.scss'
import React from 'react'
import { Route , Routes } from "react-router-dom";
import Politic from "./components/Politic";
import Condition from "./components/Condition";
import FormPage from "./components/FormPage";
import Menu from "./components/menu";
import logo from "./images/logo.svg"
import { ReactNotifications } from 'react-notifications-component'
class App extends React.Component{

    render() {
        return <div className="app">
            <ReactNotifications />

            <Menu />

            <header>
                <div className="page-container">
                    <img className='logo' src={logo} alt="logo"/>
                </div>
            </header>

            <div className="page-container">
                <Routes>
                    <Route  path="/" element={ <FormPage /> }/>
                    <Route  path="/politique-de-confidentialite" element={ <Politic /> } />
                    <Route  path="/condition-dutilisation" element={ <Condition /> } />
                </Routes>
            </div>

            </div>
        }
}
export default App;