import React, {useState} from "react";

export default function ImagePreview({file}){
    const [imageUrl , setImageUrl ] = useState(null)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        setImageUrl(reader.result)
    }
    return  <div className={'image'} style={{ backgroundImage :  `url('${imageUrl}')`  }}>
    </div>
}