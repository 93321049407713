import * as Yup from "yup";

const initialValues = {
    name: '',
    birthday: '',
    sexe: '',
    familySituation: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    cookingTime: '',
    frequency: '',
    speciality: '',
    whyParticipate: '',
    participatedYet: '',
    competitionName: '',
    photoIdentity : null,
    photoCakeOne : null,
    photoCakeTwo : null,
    photoCakeThree : null,
}

const messages = {
    required    : 'Ce champ est requis',
    email       : 'Adresse mail invalide',
    size        : 'Le fichier est trop volumineux',
    format      : 'le format du fichier n\'est pas supporté',
}

const imageValidationSchema = Yup.mixed()
    .nullable()
    .required(messages.required)
    .test(
        'FILE_SIZE',
        messages.size,
        (value) => {
            return (!value || (value && value.size <= 8000000 ) )
        }
    )
    .test(
        'FILE_FORMAT',
        messages.format,
        (value) => !value || (value && ['image/jpg','image/jpeg','image/png'].includes(value?.type) )
    );

const FormSchema = Yup.object().shape({
    name: Yup.string()
    .required(messages.required)
    .max(255),
    email: Yup.string().email(messages.email)
        .required(messages.required)
        .max(255),
    birthday: Yup.date()
        .required(messages.required)
        //.transform(parseDateString)
      /*  .transform(function (value, originalValue) {
            if (this.isType(value)) {
                return value;
            }
            const result = parse( originalValue, "dd.MM.yyyy", new Date());
        })*/
        .typeError("Date invalide"),
    sexe: Yup.string()
        .required(messages.required)
        .oneOf(['MALE', 'FEMALE']),
    familySituation: Yup.string()
    .required(messages.required)
    .max(255),
    phone: Yup.string()
        .required(messages.required)
        .max(14),
    address: Yup.string()
        .required(messages.required)
        .max(255),
    city :  Yup.number()
        .required(messages.required)
        .min(1)
        .max(58),
    cookingTime: Yup.string()
        .required(messages.required)
        .max(255),
    frequency: Yup.string()
        .required(messages.required)
        .max(255),
    speciality: Yup.string()
        .required(messages.required)
        .max(255),
    whyParticipate: Yup.string()
        .required(messages.required)
        .max(255),
    participatedYet: Yup.boolean()
        .required(messages.required),
    competitionName: Yup.string()
        .when('participatedYet', {
            is: true,
            then: (schema) => schema.required(messages.required).max(255)
        }),
    photoIdentity: imageValidationSchema,
    photoCakeOne: imageValidationSchema,
    photoCakeTwo: imageValidationSchema,
    photoCakeThree: imageValidationSchema,
});

export {
    FormSchema,
    initialValues
}