
const Labels = {
    fr : {
        name: 'Nom & prénom',
        birthday: 'Date de naissance',
        sexe: 'Sexe',
        familySituation: 'Situation familiale',
        email: 'Email',
        phone: 'Téléphone',
        address: 'Adresse',
        city: 'Ville',
        cookingTime: 'Depuis combien de temps pâtissez-vous ?',
        frequency: 'A quelle fréquence ?',
        speciality: 'Quelle est votre spécialité ?\n' +
            '(Entremets, tartes, cake design, gâteaux orientaux...) ?',
        whyParticipate: 'Pourquoi souhaitez-vous participer à l’émission ?\n' +
            ' ',
        participatedYet: 'Avez-vous déjà participé à un concours culinaire ?\n',
        competitionName: 'Titre du concours',
        photoIdentity: 'Photo de vous',
        photoCakeOne: 'Photo de vos pâtisseries N° #1',
        photoCakeTwo: 'Photo de vos pâtisseries N° #2',
        photoCakeThree: 'Photo de vos pâtisseries N° #3',

        select : 'Sélectionner...',
        formTitle : 'Formulaire d’inscription',
    },
    ar : {
        name: 'الإسم واللقب',
        birthday: 'تاريخ الميلاد',
        sexe: 'الجنس',
        familySituation: 'الوضع العائلي',
        email: 'البريد الإلكتروني',
        phone: 'رقم الهاتف',
        address: 'العنوان',
        city: 'الولاية',
        cookingTime: 'منذ متى و أنت تصنع/تصنعين الحلويات؟',
        frequency: 'كم مرة ؟',
        speciality: "ما هو تخصصك ؟" +
            "(كعك، فطائر، تصميم كيك، حلويات شرقية...)؟",
        whyParticipate:'لماذا تريد/تريدين المشاركة في المسابقة؟',
        participatedYet: 'هل سبق لك أن شاركت في مسابقة طبخ؟',
        competitionName: 'عنوان المسابقة',
        photoIdentity: 'صورة لك',
        photoCakeOne: 'صورة لحلويات من صنعك رقم 1',
        photoCakeTwo: 'صورة لحلويات من صنعك رقم 2',
        photoCakeThree: 'صورة لحلويات من صنعك رقم 3',

        select : 'اختيار...',
        formTitle : 'قسيمة الإشتراك'
    }
}

export default Labels;