
import AnimatedPage from "./animations/AnimatedPage";
import Formula from "./Form/Formula";

function  FormPage(props){
        return <AnimatedPage>
                <Formula  />
        </AnimatedPage>
}

export default FormPage;