import React from "react"

const ConditionsText = function (props){
    return <>
        <p>
            Ce service est opéré par la société NoFIlter(« Nous » ou « Notre »)
            enregistrée au RCS 16B1011438 16/00 dont le siège social est situé 34
            rue du Stade Hydra - Alger.
        </p>
        <ol start="1" type="1">
            <li>
                Veuillez lire attentivement les présentes Conditions d’Utilisation. En
                accédant et en utilisant Notre site web ainsi que tout contenu et
                fonctionnalité attaché (y compris sans limitation, tout jeu, widgets,
                lecteurs multimédias, et flux RSS) (ensemble nos « Services ») vous
                acceptez les présentes Conditions d’Utilisation, la Politique de
                Confidentialité et tout autre politique, directive et règle publiées
                par Nous sur nos Services à tout moment.
            </li>
            <li>
                Si vous n’acceptez pas les présentes Conditions d’Utilisation,
                veuillez ne pas utiliser ou accéder à nos Services.
            </li>
            <li>
                Nous pourrons mettre à jour ces Conditions d’Utilisation à tout
                moment. Veuillez consulter régulièrement les présentes Conditions
                d’Utilisation pour être informé de tout changement. Votre accès
                et/ou utilisation de nos Services après modification de nos Conditions
                d’Utilisation vaudra acceptation de ces nouvelles Conditions.
            </li>
        </ol>
        <p>
            UTILISATION DE NOS SERVICES
        </p>
        <ol start="1" type="1">
            <li>
                Vous vous engagez à utiliser nos Services uniquement à des fins
                légitimes et sans enfreindre les droits de quiconque, ni restreindre
                ou inhiber l’utilisation et la jouissance de nos Services, et dans le
                respect permanent des présentes Conditions générales et de toutes
                les lois et réglementations en vigueur
            </li>
            <li>
                En accédant à nos Services, vous acceptez d’accéder à leur contenu
                aux seules fins de votre usage personnel et non-commercial, et à
                aucune autre fin, commerciale ou autre, y compris à des fins de
                publicité ou de vente de biens ou de services. Vous vous engagez
                également à ne pas adapter, modifier ni créer de travaux dérivés
                du contenu de nos Services, à l’exception de votre usage personnel et
                non- commercial. Toute utilisation du contenu de nos Services à toute
                autre fin sera subordonnée à Notre autorisation écrite préalable.
            </li>
            <li>
                En tant que de besoin, Nous mettrons à disposition tout ou partie de
                nos Services (y compris, mais sans s'y limiter, les forums ou
                communautés) uniquement aux utilisateurs abonnés à nos Services. Si
                vous choisissez ou vous voyez communiquer un code d’identification
                d’utilisateur, un mot de passe ou toute autre information dans le cadre
                de nos procédures de sécurité, vous devez considérer ces
                informations comme confidentielles et ne les divulguer à aucun tiers.
                Toutes les informations personnelles qui Nous sont communiquées dans
                le cadre de ce processus d’inscription et/ou de toute autre interaction
                avec nos Services seront recueillies, conservées et utilisées en
                application de Notre Politique de confidentialité. Vous vous engagez
                à fournir et maintenir des informations exactes, véridiques,
                complètes et à jour. Nous nous réservons le droit, à tout moment,
                de désactiver tout code d’identification d'utilisateur ou mot de
                passe, qu’il ait été choisi par vos soins ou attribué par nos
                services, dès lors que Nous jugeons que vous avez manqué à votre
                obligation de respecter les dispositions des présentes Conditions
                générales.
            </li>
        </ol>
        <p>
            4. Dans le cadre de votre utilisation du service, vous déclarez être
            capable pour conclure une entente exécutoire. aux termes des lois
            algérienne ou d’un autre territoire pertinent.
        </p>
        <p>
            Par ailleurs, pour accéder et utiliser nos Services, vous déclarez avoir
            plus de 16 ans.
        </p>
        <p>
            Si vous avez moins de 16 ans, vous ne devez communiquer aucune information
            personnelle vous concernant ou concernant une autre personne, y compris
            votre numéro de téléphone et votre adresse postale ou électronique, à
            nos Services ou à tout autre utilisateur de nos Services.
        </p>
        <p>
            Si vous avez entre 16 et 18 ans, il vous faudra obtenir l’autorisation de
            votre parent ou tuteur légal pour pouvoir utiliser les fonctions
            interactives de nos Services, telles que la transmission ou le transfert de
            contenu vers nos Services.
        </p>
        <p>
            5. Nous ne formulons aucune déclaration stipulant que le contenu de nos
            Services est approprié ou utilisable dans un pays autre que l’Algérie. Il
            est de votre responsabilité de vérifier que votre utilisation de nos
            Services est conforme à toutes les lois et réglementations locales en
            vigueur.
        </p>
        <p>
            DROITS DE PROPRIÉTÉ INTELLECTUELLE
        </p>
        <ol start="1" type="1">
            <li>
                Tou(te)s les informations, données, textes, documents, éléments
                graphiques, logos, dessins, images, illustrations, photographies,
                vidéos, podcasts, blogs, flux RSS, widgets, lecteurs média
                intégrés, logiciels, fonctions interactives, publicités ou autres
                contenus, services ou supports (ou une partie d’entre eux) accessibles
                sur nos Services (Supports) sont protégés par des droits d’auteur,
                des marques de commerce, des droits de base de données ainsi que
                d’autres droits de propriété intellectuelle et Nous appartiennent ou
                Nous sont concédés sous licence, ou sont autrement utilisés par nos
                soins dans les limites permises par la loi ou la réglementation
                locale. Aucun des éléments contenus dans les présentes ne saurait
                être interprété comme conférant, implicitement ou non, quelque
                autorisation ou droit d’utiliser les Supports en des termes autres que
                ceux autorisés par les présentes Conditions générales.
            </li>
            <li>
                Sauf autorisation spécifiquement associée à nos Services, vous vous
                engagez à ne pas copier, conserver sur un quelconque support (y
                compris sur un autre site Internet), diffuser, transmettre,
                retransmettre, republier, émettre, modifier ni afficher en public une
                quelconque partie de nos Services sans Notre consentement écrit
                préalable (et le consentement écrit préalable du propriétaire des
                droits de propriété intellectuelle concernés).
            </li>
        </ol>
        <p>
            CONTRIBUTIONS DE L’UTILISATEUR À NOS SERVICES
        </p>
        <p>
            VEUILLEZ LIRE CET ARTICLE ATTENTIVEMENT AVANT DE TRANSFÉRER OU DE
            TRANSMETTRE DES CONTENUS SUR LE SITE. SI VOUS NE SOUHAITEZ PAS ACCORDER LES
            AUTORISATIONS ÉNONCÉES DANS LES PRÉSENTES CONDITIONS, VEUILLEZ NE PAS
            ENVOYER DE CONTRIBUTION SUR LE SITE.
        </p>
        <ol start="1" type="1">
            <li>
                Vous restez détenteur des droits d’auteur associés à toute
                Contribution originale que vous envoyez. En envoyant une Contribution
                sur l'un de nos Services et dans le cas où vous seriez sélectionné,
                vous Nous concédez, de façon irrévocable, inconditionnelle et
                gratuite, une licence non-exclusive, internationale, libre de droits et
                pouvant donner lieu à l’octroi d’une sous-licence concernant
                l'intégralité des droits associés à votre Contribution de sorte que
                Nous, et nos éventuels successeurs, pourrons faire n’importe quel
                usage de votre Contribution de l’utilisateur, y compris mais sans s’y
                limiter, le droit de copier, reproduire, modifier éditer, adapter,
                reformatter, traduire, créer des travaux dérivés, intégrer dans
                d’autres travaux, transmettre, diffuser, exécuter, lire, émettre,
                commercialiser et mettre autrement à la disposition du public votre
                Contribution (y compris les éventuels renouvellements, relances,
                rétrogradations et extensions) et ce dans toute la mesure du possible
                pour toute la durée légale des droits d’auteur.
            </li>
            <li>
                L’autorisation que vous Nous avez accordée n’est pas exclusive et, par
                conséquent, vous pouvez continuer à faire n'importe quel usage de
                votre Contribution, sur n’importe quel support, y compris autoriser
                d’autres personnes à l’utiliser, pour autant que cette utilisation
                n’interfère pas avec et n’entrave pas les droits que vous Nous avez
                concédés.
            </li>
            <li>
                Vous serez seul responsable de vos Contributions et des conséquences
                de leur envoi sur nos Services. Vous garantissez que :
            </li>
        </ol>
        <p>
            (a) votre Contribution est le fruit de votre travail original et vous
            détenez tous les droits associés à votre Contribution et/ou vous
            disposez de tou(te)s les licences, droits, autorisations et permissions
            requis pour utiliser et Nous autoriser à utiliser tout ou partie des
            droits de propriété intellectuelle (y compris mais sans s’y limiter, les
            droits d’auteur) associés à votre Contribution pour Nous permettre
            d’utiliser votre Contribution de la façon envisagée par les présentes
            Conditions ;
        </p>
        <p>
            (b) aucun élément de votre Contribution n’est de nature diffamatoire,
            obscène, indécente, pornographique, sexuellement explicite, outrageante,
            intimidante ou offensante, n’incite à la haine raciale, culturelle ou
            religieuse, n’imite ou semble imiter une autre personne, n’enfreint une
            quelconque loi ou réglementation et n’encourage de comportements
            susceptibles de constituer une infraction criminelle ou de donner lieu à
            une responsabilité civile et/ou n’est autrement répréhensible et/ou
            contraire à une quelconque loi ou réglementation en vigueur en Algérie ou
            dans un autre pays ;
        </p>
        <p>
            (c) vous vous abstiendrez de recueillir, obtenir, compiler, réunir,
            transmettre, reproduire, supprimer, réviser, visionner ou afficher les
            supports, données ou informations, qu'ils soient personnellement
            identifiables ou non, publiés par ou concernant une autre personne,
            société ou entreprise, en rapport avec votre Contribution ou avec votre
            utilisation de nos Services, sauf autorisation expresse préalable
            accordée par cette personne (ou son parent ou tuteur légal si cette
            personne a moins de 18 ans), société ou entreprise ;
        </p>
        <p>
            (d) votre Contribution ne contient aucun virus, fichier corrompu, robot
            d’annulation de message (« cancelbot »), programme à ver (« worm ») ou
            autre code malveillant destiné à interrompre, détruire, limiter la
            fonctionnalité ou dérégler un logiciel, matériel, moyen de
            télécommunication, réseau, serveur ou autre équipement, ni aucun
            logiciel publicitaire, logiciel espion, cheval de Troie ou tout autre
            support destiné à endommager, perturber, intercepter ou s’approprier des
            données ou des informations personnelles ;
        </p>
        <p>
            (e) vous vous abstiendrez d’imiter un en-tête de paquet TCP/IP ou une
            partie des informations de l’en-tête d’une Contribution, pour quelque
            motif que ce soit ;
        </p>
        <p>
            (f) vous vous abstiendrez de communiquer des supports ou d’exécuter des
            actions entraînant la transmission de courriels indésirables, de lettres
            en chaîne, de sondages, de concours, de programmes de vente pyramidale, de
            messages en double ou non-sollicités, et tout ce qui peut s’apparenter à
            du «pollupostage» ou du « hameçonnage » ; et
        </p>
        <p>
            (g) il ne Nous sera pas demandé de payer ou d’engager des sommes à
            l’encontre d'une personne ou d'une entité en conséquence de Notre
            utilisation ou exploitation de votre Contribution, quelle qu’elle soit.
        </p>
        <p>
            4. Vos Contributions ne seront pas considérées comme confidentielles et
            Nous sommes autorisés à utiliser, copier, diffuser et partager celles-ci
            avec le diffuseur de l’émission (Groupe M6) ou avec tout tiers impliqué
            dans la production de l’émission (et ce compris la sélection des
            candidats). Nous nous réservons également le droit de communiquer des
            informations pertinentes à un tiers qui estime qu’un contenu publié ou
            transféré par vos soins sur Notre site constitue une violation de ses
            droits de propriété intellectuelle, de sa vie privée ou d’une loi ou
            réglementation en vigueur en Algérie ou dans un autre pays.
        </p>
        <p>
            SUPPRESSION DES CONTENUS
        </p>
        <ol start="1" type="1">
            <li>
                Nous avons le droit, à Notre discrétion, mais ne sommes pas
                contraints de (a) procéder à un examen actif de vos Contributions à
                nos Services ; et/ou de (b) supprimer des Contributions qui ne
                respectent pas les présentes Conditions.
            </li>
            <li>
                Vous ne détenez aucun droit de propriété sur les informations
                fournies lors du processus d’inscription. Si vous ne souhaitez plus
                accéder à nos Services ou si Nous supprimons votre accès à nos
                Services par suite d’une utilisation abusive de votre part, toutes vos
                informations, y compris celles du profil, des informations et
                questionnaires pourront être supprimées de Notre base de données.
                Des données peuvent toutefois subsister pour une certaine période en
                raison du délai de traitement nécessaire à leur suppression.
            </li>
        </ol>
        <p>
            Nous nous réservons le droit, à Notre seule discrétion et à tout
            moment, sans responsabilité et avec ou sans préavis, (a) de ne pas
            publier une de vos Contribution sur nos Services ; (b) de supprimer,
            suspendre ou désactiver l’accès à vos Contributions; (c) de révoquer
            votre inscription (le cas échéant) et votre droit d'accéder et/ou
            d’utiliser nos Services ou de soumettre vos Contributions ; et (d)
            d’utiliser tous les moyens techniques, juridiques, opérationnels ou autres
            à Notre disposition pour faire respecter les présentes Conditions, y
            compris mais sans s’y limiter, en bloquant des adresses IP spécifiques ou
            en désactivant votre inscription et/ou votre nom d'utilisateur et votre
            mot de passe (le cas échéant).
        </p>
        <p>
            UTILISATION DES INFORMATIONS CONCERNANT NOS SERVICES
        </p>
        <ol start="1" type="1">
            <li>
                Les informations publiées par le biais de nos Services sont uniquement
                destinées à communiquer des informations d’ordre général. Nous ne
                garantissons en aucun cas que les Supports sont exacts ou dénués
                d’erreur, et Nous déclinons tout engagement ou responsabilité
                résultant de l’utilisation du contenu de nos Services par vous ou par
                toute personne susceptible d’avoir connaissance des contenus de nos
                Services.
            </li>
            <li>
                Vous reconnaissez que, lors de l’utilisation de nos Services, vous
                pourrez être confronté à des Supports provenant de sources diverses
                et que Nous ne sommes pas responsables du contenu, de la fidélité, de
                l’exactitude, de l’utilité ni de la sécurité de ces Supports. Nous
                n’appuyons aucune Contribution ni aucun(e) opinion, recommandation ou
                conseil exprimé(e) dans une Contribution et Nous déclinons toute
                responsabilité en rapport avec cette Contribution ou tout autre
                support.
            </li>
            <li>
                Nous avons pour but de mettre nos Services régulièrement à jour et
                pourrons en modifier le contenu à tout moment. Nous pourrons suspendre
                l’accès à nos Services ou les fermer de manière indéterminée à
                tout moment et à Notre seule discrétion. Les Supports peuvent devenir
                obsolètes à tout moment et Nous n’avons aucune obligation de les
                actualiser.
            </li>
        </ol>
        <p>
            GARANTIES ET DÉGAGEMENT DE RESPONSABILITÉ
        </p>
        <p>
            Vous reconnaissez et acceptez expressément que :
        </p>
        <p>
            C'est en toute connaissance de cause que vous utilisez nos Services qui
            vous offrent un contenu accessible selon la qualité du réseau Internet.
            Notamment, Nous ne fournissons aucune garantie expresse ou implicite
            concernant la qualité et la compatibilité de nos Services aux usages
            spécifiques que vous en faites, et Nous emploierons nos meilleurs efforts
            pour maintenir nos Services dans un état opérationnel.
        </p>
        <p>
            Nous ne sommes en mesure de vous apporter la garantie que nos Services
            répondront exactement à vos attentes et Nous ferons nos meilleurs efforts
            pour qu'aucune erreur n'apparaisse au cours de votre utilisation de nos
            Services. Nous ne garantissons pas que les résultats, les informations,
            obtenus sont exempts de toute erreur ou défaut. Nous dégageons toute
            responsabilité quant aux éventuelles contestations, actions ou recours de
            tiers qui viendraient à se prévaloir de droits notamment privatifs, sur
            tout contenu non créé par Nous.
        </p>
        <p>
            Nous pouvons à tout moment, modifier, interrompre temporairement ou
            définitivement nos Services et ce sans avoir à vous en informer au
            préalable. Nous ne prenons aucun engagement de maintenir un accès
            permanent et ininterrompu à nos Services.
        </p>
        <p>
            LIMITATION DE RESPONSABILITÉ
        </p>
        <p>
            1. Nous déclinons toute responsabilité associée à une Contribution
            envoyée par l’un de nos utilisateurs. Votre utilisation de nos Services,
            des Supports et/ou des Contributions se fait à vos risques et périls.
        </p>
        <ol start="2" type="1">
            <li>
                Vous êtes seul responsable du format, du contenu et de l’exactitude de
                toute information ou élément Nous étant fournis. Dans toute la
                mesure permise par la loi, Nous, les autres membres du groupe de
                sociétés NoFilter et les tiers en relation avec Nous ne pourrons
                être tenus pour responsable en cas de préjudice ou dommage direct,
                indirect, consécutif, spécial ou exemplaire (qu’il ait été prévu,
                prévisible, connu ou autre) résultant de ou se rapportant à
                l’utilisation de nos Services, y compris mais sans s'y limiter : (a) un
                préjudice et/ou dommage causé à des personnes ou des biens à la
                suite d'une déclaration, notamment une déclaration avérée ou
                présumée diffamatoire ; (b) une perte subie par un tiers, notamment
                la violation des droits de propriété intellectuelle ou de la vie
                privée ; (c) la perte de données ; (d) la perte de revenus ou de
                bénéfices anticipés ; (e) la perte d’activité ou de contrats ; (f)
                la perte d’opportunité ; (g) la perte d’économies anticipées ; (h)
                la perte de temps de gestion ou d’administration ; ou (i) la perte de
                clientèle ou une dégradation de la réputation.
            </li>
            <li>
                Cette condition ne s’applique pas à Notre responsabilité en cas de
                décès ou de blessure corporelle résultant de Notre négligence,
                d’une fausse déclaration frauduleuse ou d'une fausse déclaration
                relative à une affaire fondamentale, ou à toute autre responsabilité
                ne pouvant être exclue ni limitée en vertu des lois ou
                réglementations applicables.
            </li>
            <li>
                Nous déclinons toute responsabilité en cas de manquement aux
                présentes Conditions provoqué par des circonstances indépendantes de
                Notre volonté.
            </li>
        </ol>
        <p>
            REMBOURSEMENT
        </p>
        <p>
            Vous vous engagez à Nous rembourser intégralement, ainsi qu’à nos
            dirigeants, administrateurs, employés, mandataires, représentants,
            concédants de licence, fournisseurs et prestataires de services
            opérationnels, sur demande, en cas de réclamations, actions en justice,
            poursuites, demandes, dommages-intérêts, pertes, dettes, coûts et frais
            subis ou raisonnablement engagés par nos soins à la suite de ou en
            rapport avec votre consultation et utilisation de nos Services de façon
            contraire aux présente Conditions ou à une quelconque loi ou
            réglementation en vigueur.
        </p>
        <p>
            VIRUS, PIRATAGE ET AUTRES DÉLITS
        </p>
        <p>
            Vous ne devez en aucun cas vous livrer à une utilisation inappropriée de
            nos Services en introduisant, transmettant ou organisant l’envoi de virus,
            d'un fichier corrompu, d’un robot d’annulation de message (cancelbot), d’un
            cheval de Troie, d’un ver, d'une bombe logique ou à retardement, d’un
            enregistreur de frappe, d’un logiciel espion, d’un logiciel publicitaire ou
            de tout autre support destiné à altérer le fonctionnement d’un logiciel
            ou matériel informatique ou de tout autre équipement de
            télécommunication, ou à perturber, intercepter ou s’approprier des
            données ou des informations personnelles. Vous ne devez pas tenter
            d’obtenir un accès non autorisé à nos Services, aux serveurs qui les
            hébergent ni à un quelconque serveur, ordinateur ou base de données
            connecté(e) à nos Services. Vous ne devez pas attaquer nos Services par
            le biais d’un déni de service, de la diffusion d'un déni de service ou
            tout autre type d’attaque.
        </p>
        <ol start="1" type="1">
            <li>
                Nous signalerons tout manquement de ce type aux autorités judiciaires
                et administratives compétentes et coopérerons avec ces autorités en
                leur communiquant votre identité. En cas de manquement de ce type,
                votre droit d'utiliser nos Services prendra fin instantanément.
            </li>
            <li>
                Nous déclinons toute responsabilité en cas de préjudice ou dommage
                causé par une attaque de diffusion de déni de service, des virus ou
                un autre support technologique malfaisant susceptible d'infecter votre
                équipement informatique, vos programmes informatiques, vos données ou
                d’autres supports protégés en raison de votre utilisation de nos
                Services, de votre téléchargement de Supports sur nos Services ou de
                tout autre support se trouvant sur un autre site Internet relié au
                nôtre.
            </li>
        </ol>
        <p>
            LIENS À DESTINATION ET EN PROVENANCE DE NOS SERVICES
        </p>
        <p>
            <em>Liens en provenance de nos Services</em>
        </p>
        <ol start="1" type="1">
            <li>
                Nous n’avons aucun contrôle sur le contenu ou la disponibilité des
                sites tiers auxquels vous pouvez accéder par l’intermédiaire de nos
                Services, et ces liens sont fournis uniquement à titre d'information.
                La consultation d’un site relié se fait à vos risques et périls et
                il vous revient de prendre toutes les mesures de sécurité
                nécessaires pour vous protéger contre les virus ou autres éléments
                destructeurs.
            </li>
            <li>
                Nous désapprouvons et déclinons toute responsabilité (directe ou
                indirecte) en cas de contenu, publicité, produits, services, opinions
                exprimées ou informations figurant ou accessibles sur des sites
                Internet tiers (y compris mais sans s’y limiter, le paiement et la
                livraison de produits ou services) ou en cas de dommage, préjudice ou
                outrage causé par ou en rapport avec votre consultation et utilisation
                de ces sites tiers et des services qu’ils proposent. Les Conditions,
                termes, garanties ou déclarations associés aux transactions
                réalisées sur des sites tiers n’engagent que vous et le fournisseur
                de ce site et/ou de tout service concerné.
            </li>
        </ol>
        <p>
            <em>Liens à destination de nos Services</em>
        </p>
        <p>
            Les liens vers nos Services ne sont pas autorisés sur les « Sites Internet
            exclus » suivants :
        </p>
        <ul type="disc">
            <li>
                Sites Internet incitant à la haine, qu’elle soit basée sur des
                critères de race, de religion, de sexe, de sexualité ou autre, ou
                faisant la promotion, encourageant ou favorisant les comportements
                antisociaux ;
            </li>
            <li>
                Sites Internet faisant la promotion, encourageant ou favorisant la
                violence ;
            </li>
            <li>
                Sites Internet faisant la promotion, encourageant ou favorisant le
                terrorisme ou d’autres activités à risque
            </li>
        </ul>
        <p>
            pour la sécurité nationale de l’Algérie;
        </p>
        <ul type="disc">
            <li>
                Sites Internet pratiquant la discrimination contre un groupe social
                spécifique ou exploitant des populations vulnérables au sein de la
                société ;
            </li>
            <li>
                Sites Internet faisant la promotion, favorisant ou encourageant une
                activité illégale ;
            </li>
            <li>
                Sites Internet présentant un contenu mensonger, pornographique,
                diffamatoire, illégal ou passible de poursuites en vertu de la loi
                française ; ou
            </li>
            <li>
                Sites Internet violant la vie privée d’une ou plusieurs personnes.
            </li>
        </ul>
        <p>
            PUBLICITÉ ET PARRAINAGE
        </p>
        <p>
            Une partie ou l’intégralité de nos Services peuvent contenir, en tant que
            de besoin, des supports publicitaires ou de parrainage. Les annonceurs et
            commanditaires sont tenus de s’assurer que tous les supports transmis pour
            inclusion dans nos Services sont conformes aux lois, réglementations et
            codes en vigueur ainsi qu’aux présentes Conditions. Nous déclinons toute
            responsabilité en cas d’erreur ou d’inexactitude identifiée sur ces
            supports publicitaires ou de parrainage.
        </p>
        <p>
            LOGICIELS
        </p>
        <p>
            En tant que de besoin, certains logiciels, qui constituent nos travaux
            protégés ou les travaux protégés de nos fournisseurs, seront
            disponibles en téléchargement par l’intermédiaire de nos Services. Vous
            êtes autorisé à utiliser ces logiciels de façon non-exclusive et
            uniquement aux fins prévues. Vous n’êtes autorisé à utiliser ces
            logiciels à aucune autre fin ni à rediffuser, vendre, décompiler,
            rétroconcevoir, démonter ou traiter d’une quelconque autre façon ces
            logiciels.
        </p>
        <p>
            CONFIDENTIALITÉ
        </p>
        <p>
            Nous prenons très au sérieux votre vie privée. Toutes les informations
            personnelles que vous Nous communiquez dans le cadre d’un processus
            d’inscription et/ou de toute autre interaction avec nos Services seront
            recueillies, conservées et utilisées en application de Notre Politique de
            confidentialité. En utilisant nos Services, vous consentez à ce recueil
            et ce traitement, et vous confirmez l’exactitude de toutes les données
            communiquées.
        </p>
        <p>
            ORDRE DE PRÉSÉANCE
        </p>
        <p>
            En cas de conflit entre les présentes Conditions et des conditions
            spécifiques figurant sur nos Services et applicables à des supports
            spécifiques, ces dernières prévaudront.
        </p>
        <p>
            DIVISIBILITÉ
        </p>
        <p>
            Dans le cas où l’une des présentes Conditions est réputée contraire à
            la loi, nulle ou autrement non exécutoire à l’effet des lois d'un État
            ou d'un pays dans lequel les présentes Conditions sont destinées à
            prendre effet, dans la mesure où la juridiction reconnaissant cette
            condition comme contraire à la loi, nulle ou non exécutoire, elle sera
            dissociée et supprimée des présentes Conditions. Les Conditions
            restantes resteront en vigueur et de plein effet, et conserveront leur
            caractère contraignant et exécutoire.
        </p>
        <p>
            LOI APPLICABLE ET JURIDICTION
        </p>
        <p>
            Les termes des présentes Conditions d’Utilisation de nos services sont
            régis et interprétés conformément aux lois algérienne.
        </p>
        <p>
            Les tribunaux algériens sont compétents pour résoudre tout conflit
            concernant ces Conditions d'Utilisation de nos services.
        </p>
    </>
}

export default ConditionsText;