export default function  PoliticText(){
    return <>
        <p><strong>Qui sommes-nous?</strong></p>
        <p>NoFilter est une soci&eacute;t&eacute; de production audiovisuelle.&nbsp;</p>
        <p>NoFilter, SARL inscrite au RCS sous le num&eacute;ro 16B1011438 16/00 (ci-apr&egrave;s d&eacute;nomm&eacute;es &laquo; NOF &raquo;, &laquo; nous &raquo; ou &laquo; notre &raquo;) est le responsable de traitement des donn&eacute;es personnelles que nous collectons &agrave; l&rsquo;occasion de votre candidature, participation et/ou contribution &agrave; un ou plusieurs des programmes d&eacute;velopp&eacute;s et/ou produits par NOF seule ou avec un tiers.</p>
        <p><strong>&Agrave; qui cette politique s&rsquo;applique-t-elle?</strong></p>
        <p>Cette politique de confidentialit&eacute; est destin&eacute;e aux &laquo; contributeurs &raquo; de nos programmes. Par contributeur, il convient d&rsquo;entendre :</p>
        <ul>
            <li>Les animateurs, jur&eacute;s, experts,</li>
            <li>Les auteurs,</li>
            <li>Les candidats qui postulent pour participer &agrave; nos programmes et ceux qui sont s&eacute;lectionn&eacute;s pour y participer,</li>
            <li>Les autres personnes qui contribuent &agrave; nos programmes telles que personnes interview&eacute;es ou nous fournissant des vid&eacute;os ou photos ou nous autorisant &agrave; filmer dans leurs propri&eacute;t&eacute;s,</li>
            <li>Les agents ou repr&eacute;sentant des contributeurs.</li>
        </ul>
        <p>Cette politique de confidentialit&eacute; a pour objet de vous informer sur les donn&eacute;es personnelles que nous collectons et la mani&egrave;re dont nous les traitons.</p>
        <p><strong>Quelles sont les donn&eacute;es personnelles que nous traitons ?</strong></p>
        <p>Dans le cadre de votre participation et/ou contribution &agrave; l&rsquo;un ou plusieurs de nos programmes, nous sommes amen&eacute;s &agrave; collecter des donn&eacute;es personnelles vous concernant telles que : vos nom(s) et pr&eacute;nom(s), adresse postale et &eacute;lectronique, votre genre, date de naissance, &acirc;ge, num&eacute;ro de t&eacute;l&eacute;phone, num&eacute;ro et/ou copie de votre passeport ou carte nationale d&rsquo;identit&eacute;, r&eacute;sidence fiscale, coordonn&eacute;es bancaires, d&eacute;tails portant sur votre parcours professionnel et votre histoire personnelle, captation vid&eacute;ographique et/ou photographique de votre image, etc.</p>
        <p>Les cat&eacute;gories de donn&eacute;es personnelles n&eacute;cessaires d&eacute;pendent de la nature du programme auquel vous candidatez et/ou participez. Nous pourrions ainsi par exemple avoir besoin de recueillir certaines donn&eacute;es de sant&eacute; ou votre &eacute;ventuel pass&eacute; judiciaire.</p>
        <p>Comment nous collectons vos donn&eacute;es personnelles :</p>
        <ul>
            <li>donn&eacute;es personnelles fournies par vous : par le biais de tout formulaire ou document que vous compl&eacute;tez &agrave; l&rsquo;occasion de votre candidature, participation ou contribution &agrave; l&rsquo;un de nos programmes (dossier de candidature, fiche de renseignement, autorisation de diffusion, contrat, entretien t&eacute;l&eacute;phonique, captation audiovisuelle et/ou d&rsquo;une mani&egrave;re g&eacute;n&eacute;rale toute correspondance &eacute;crite ou orale avec NOF concernant votre contribution). Dans certains cas, vous pouvez nous fournir des informations sur d'autres personnes, par exemple lorsque vous envoyez une photo contenant leur image. Vous devez vous assurer que (i) vous avez la permission de nous fournir ces informations les concernant ; et (ii) vous devez les avoir mis au courant des termes de notre Politique de confidentialit&eacute; ;</li>
            <li>donn&eacute;es personnelles provenant d&rsquo;autres sources : il s&rsquo;agit de donn&eacute;es personnelles (i) disponibles en acc&egrave;s libre et public, (ii) disponibles sur une plateforme de r&eacute;seau social, (iii) communiqu&eacute;es par vos agents ou interm&eacute;diaires et entreprises de production.</li>
        </ul>
        <p><strong>Pourquoi et sur quelle base traitons-nous vos donn&eacute;es personnelles ?</strong></p>
        <p>Nous utilisons vos donn&eacute;es personnelles pour g&eacute;rer votre candidature et/ou participation et/ou contribution &agrave; l&rsquo;un ou plusieurs de nos programmes.</p>
        <p>Dans l&rsquo;hypoth&egrave;se o&ugrave; vous ne souhaiteriez pas que nous collections et traitions vos donn&eacute;es personnelles, nous vous informons que nous ne serons pas en mesure de donner une suite favorable &agrave; votre candidature ni de confirmer votre participation.</p>
        <p>Si NOF pr&eacute;voit d&rsquo;utiliser vos informations personnelles &agrave; d&rsquo;autres fins que celles mentionn&eacute;es ci-dessus, nous nous engageons &agrave; vous en informer au pr&eacute;alable. Vous aurez &eacute;galement la possibilit&eacute; de ne pas donner votre consentement ou de le retirer pour toute utilisation autre qu&rsquo;&eacute;num&eacute;r&eacute;es ci-dessus.</p>
        <p>Pour traiter vos donn&eacute;es personnelles, nous nous basons sur un ou plusieurs des fondements ci-dessous vis&eacute;s :</p>
        <p><strong>1. Le consentement</strong></p>
        <p>Cette hypoth&egrave;se implique que vous avez consenti de mani&egrave;re expresse au traitement de vos donn&eacute;es personnelles soit par le biais d&rsquo;un formulaire que vous auriez compl&eacute;t&eacute;, soit par le biais d&rsquo;&eacute;changes de courriels.</p>
        <p><strong>2. L&rsquo;ex&eacute;cution d&rsquo;un contrat avec vous</strong></p>
        <p>Cette hypoth&egrave;se suppose qu&rsquo;un document de nature contractuelle a &eacute;t&eacute; conclu entre vous et NOF, et ce quel que soit la phase de production du programme (casting, participation au programme, etc.). Il pourra s&rsquo;agir de tout type de document : contrat, autorisation de diffusion, r&egrave;glement de jeu, etc.</p>
        <p><strong>3. La n&eacute;cessit&eacute; de se conformer &agrave; une obligation l&eacute;gale</strong></p>
        <p>Nous pouvons &ecirc;tre amen&eacute;s &agrave; traiter vos donn&eacute;es personnelles en vue de nous conformer aux obligations l&eacute;gales qui s&rsquo;imposent &agrave; nous en notre qualit&eacute; de soci&eacute;t&eacute; de production audiovisuelle (d&eacute;clarations aux organismes sociaux et/ou fiscaux, r&eacute;ponse &agrave; un droit de communication d&rsquo;une administration et/ou &agrave; une demande des autorit&eacute;s, etc.).</p>
        <p><strong>4. Notre int&eacute;r&ecirc;t l&eacute;gitime</strong></p>
        <p>Nous utilisons &eacute;galement vos donn&eacute;es personnelles car nous y avons un int&eacute;r&ecirc;t l&eacute;gitime en qualit&eacute; de soci&eacute;t&eacute; de production audiovisuelle, notamment en vue :</p>
        <ul>
            <li>d&rsquo;envisager votre participation et/ou contribution &agrave; l&rsquo;un de nos programmes ;</li>
            <li>de r&eacute;pondre &agrave; toute question que vous auriez concernant votre candidature/participation/contribution ;</li>
            <li>de traiter avec tout tiers agissant en votre nom tel qu&rsquo;agent, manager etc. ;</li>
            <li>de produire le ou les programmes audiovisuels auxquels vous candidatez et/ou participez et/ou apportez votre contribution ;</li>
            <li>d&rsquo;exploiter ledit programme sur tous supports et par tous moyens dans le monde entier et pour la dur&eacute;e des droits attach&eacute;s au programme. Il est pr&eacute;cis&eacute; qu&rsquo;&agrave; l&rsquo;issue de ladite dur&eacute;e, nous conservons une copie du programme sous la forme d&rsquo;archive venant compl&eacute;ter le catalogue de nos productions ;</li>
            <li>de fournir &agrave; des tiers (agences de voyage, compagnies a&eacute;riennes, h&ocirc;tels etc.) certaines de vos donn&eacute;es personnelles afin de vous faire b&eacute;n&eacute;ficier de services ou avantages auxquels vous pourriez pr&eacute;tendre dans le cadre de votre candidature/participation/contribution &agrave; l&rsquo;un de nos programmes ;</li>
            <li>de communiquer certaines informations concernant tout ou partie du programme ou de l&rsquo;une quelconque des phases de sa production, &agrave; nos conseils, au diffuseur, &agrave; nos compagnies d&rsquo;assurance et &agrave; nos &eacute;ventuels partenaires et coproducteurs ainsi qu&rsquo;&agrave; toute autre entit&eacute; du groupe auquel nous appartenons ;</li>
            <li>de v&eacute;rifier votre &acirc;ge, votre identit&eacute; et toute autre information que nous pourrions exiger dans le cadre de la production et l&rsquo;exploitation du programme auquel vous souhaitez participer ;</li>
            <li>de nous conformer &agrave; nos obligations r&egrave;glementaires, &agrave; nos exigences comptables et fiscales, &agrave; nos exigences en mati&egrave;re d&rsquo;audit ;</li>
            <li>de nous prot&eacute;ger contre la fraude, l&rsquo;abus de confiance, le vol de mat&eacute;riaux exclusifs et autres infractions financi&egrave;res ou relevant du droit p&eacute;nal des affaires (dans la limite o&ugrave; cela ne nous est pas impos&eacute; par la loi) ;</li>
            <li>de surveiller les communications h&eacute;berg&eacute;es par nos syst&egrave;mes d&rsquo;information.</li>
        </ul>
        <p>Naturellement, nous veillons scrupuleusement &agrave; ce que les traitements de donn&eacute;es personnelles r&eacute;alis&eacute;s dans notre int&eacute;r&ecirc;t l&eacute;gitime en vue de poursuivre les finalit&eacute;s ci-avant pr&eacute;sent&eacute;es ne portent pas atteinte &agrave; votre vie priv&eacute;e ou &agrave; l&rsquo;un quelconque de vos libert&eacute;s et droits fondamentaux.</p>
        <p><strong>Avec qui partageons-nous vos donn&eacute;es personnelles ?</strong></p>
        <p>Vos donn&eacute;es personnelles sont utilis&eacute;es par les &eacute;quipes de NOF (casting, production, &eacute;quipes comptables et financi&egrave;res) et pourront &ecirc;tre partag&eacute;es avec (i) nos compagnies d&rsquo;assurances et leur conseil ainsi que (ii) nos prestataires, partenaires, sous-traitants, diffuseurs et toute entit&eacute; de notre groupe, en vue de g&eacute;rer votre candidature et/ou participation et/ou contribution &agrave; l&rsquo;un de nos programmes.</p>
        <p>En tout &eacute;tat de cause, nous ne transmettrons que les donn&eacute;es n&eacute;cessaires &agrave; la fourniture du service d&eacute;livr&eacute; par les tiers susvis&eacute;s, &eacute;tant pr&eacute;cis&eacute; par ailleurs que ces derniers seront tenus de respecter nos instructions et de ne pas utiliser vos donn&eacute;es personnelles afin de servir leurs propres int&eacute;r&ecirc;ts commerciaux.</p>
        <p>En dehors des cas susvis&eacute;s, nous ne partagerons vos donn&eacute;es personnelles avec des tiers qu&rsquo;avec votre consentement. Nous pourrons cependant divulguer vos informations personnelles si nous y sommes contraints par la loi ou une juridiction ou dans le but de pr&eacute;venir la fraude ou tout autre crime.</p>
        <p>Enfin, nous nous r&eacute;servons le droit de transf&eacute;rer vos donn&eacute;es personnelles dans l&rsquo;hypoth&egrave;se o&ugrave; nous vendrions ou transf&eacute;rerions tout ou partie de nos activit&eacute;s ou actifs &agrave; un tiers. En pareille hypoth&egrave;se, le responsable du traitement de vos donn&eacute;es personnelles sera alors ce tiers acqu&eacute;reur. Nous ferons les efforts raisonnables, dans de telles circonstances, afin de vous pr&eacute;venir &agrave; l&rsquo;avance du transfert de vos informations.</p>
        <p><strong>Comment s&eacute;curisons-nous vos donn&eacute;es personnelles ?</strong></p>
        <p>Nous utilisons des mesures de protection organisationnelles et technologiques appropri&eacute;es afin d&rsquo;&ecirc;tre prot&eacute;g&eacute;s contre la perte, l'utilisation abusive ou l'acc&egrave;s non autoris&eacute;, la divulgation, la modification ou la destruction des informations que nous d&eacute;tenons vous concernant. Nous nous assurons &eacute;galement de faire en sorte que nos fournisseurs d&rsquo;acc&egrave;s en font de m&ecirc;me.</p>
        <p>Si vous pensez que vos informations personnelles plac&eacute;es sous notre contr&ocirc;le ont &eacute;t&eacute; compromises, veuillez nous contacter imm&eacute;diatement en utilisant les coordonn&eacute;es vis&eacute;es dans la rubrique &laquo; Contacts &raquo; ci-dessous.</p>
        <p>Comme indiqu&eacute; dans la section pr&eacute;c&eacute;dente, nous pourrons &ecirc;tre amen&eacute;s &agrave; partager vos informations avec d'autres soci&eacute;t&eacute;s du groupe NOF ou avec des fournisseurs de services tiers avec lesquels nous avons contract&eacute;.</p>
        <p>Ces soci&eacute;t&eacute;s peuvent &ecirc;tre situ&eacute;es, ou utiliser des &eacute;quipements informatiques situ&eacute;s dans des pays diff&eacute;rents de ceux dans lesquels vos informations sont collect&eacute;es. Si tel est le cas, nous prendrons les mesures n&eacute;cessaires afin de garantir &agrave; vos informations personnelles un niveau de protection &eacute;quivalent.</p>
        <p>Dans l&rsquo;hypoth&egrave;se o&ugrave; nous transf&eacute;rerions vos donn&eacute;es personnelles, nous nous assurons qu&rsquo;un degr&eacute; de protection similaire est appliqu&eacute; en veillant &agrave; ce qu'au moins l'une des garanties suivantes soient mise en &oelig;uvre :</p>
        <ul>
            <li>Nous ne transf&eacute;rerons vos donn&eacute;es personnelles qu'&agrave; des pays qui ont &eacute;t&eacute; consid&eacute;r&eacute;s par la Commission europ&eacute;enne comme assurant un niveau ad&eacute;quat de protection des donn&eacute;es personnelles.</li>
            <li>Lorsque nous utilisons certains fournisseurs d&rsquo;acc&egrave;s, nous pouvons utiliser des contrats sp&eacute;cifiques qui conf&egrave;rent aux donn&eacute;es personnelles la m&ecirc;me protection.</li>
            <li>Lorsque nous utilisons des fournisseurs d&rsquo;acc&egrave;s bas&eacute;s aux &Eacute;tats-Unis, nous pouvons leur transf&eacute;rer des donn&eacute;es s'ils font partie de la Privacy Shield (mesure de protection des donn&eacute;es personnelles), ce qui les oblige &agrave; fournir une protection similaire aux donn&eacute;es personnelles.</li>
        </ul>
        <p></p>
        <p><strong>Combien de temps conservons-nous vos donn&eacute;es personnelles ?</strong></p>
        <p>Nous conserverons vos informations personnelles dans nos syst&egrave;mes aussi longtemps que n&eacute;cessaire pour la finalit&eacute; poursuivie par leur collecte ou aussi longtemps que cela est stipul&eacute; dans tout contrat applicable que vous avez pass&eacute; avec nous.</p>
        <p>Ainsi, pour les candidats ayant &eacute;t&eacute; s&eacute;lectionn&eacute;s et ayant en cons&eacute;quence particip&eacute; et/ou contribu&eacute; au programme, les donn&eacute;es personnelles seront conserv&eacute;es pendant la dur&eacute;e des droits attach&eacute;s au programme telle qu&rsquo;elle aura &eacute;t&eacute; conc&eacute;d&eacute;e dans le contrat avec NOF.</p>
        <p>Pour les candidats non s&eacute;lectionn&eacute;s pour participer aux programmes, vos donn&eacute;es personnelles seront conserv&eacute;es :</p>
        <ul>
            <li>pour une dur&eacute;e maximale de 12 (douze) mois &agrave; compter du d&eacute;but du casting si vous nous avez indiqu&eacute; ne pas souhaiter que vos donn&eacute;es personnelles soient conserv&eacute;es pour de futures saisons du programme, afin de respecter nos obligations l&eacute;gales, et r&eacute;soudre les litiges &eacute;ventuels pouvant survenir en lien avec le programme ;</li>
            <li>pour une dur&eacute;e maximale de 2 (deux) ans &agrave; compter du d&eacute;but du casting si vous nous avez indiqu&eacute; souhaiter que vos donn&eacute;es personnelles soient conserv&eacute;es pour de futures saisons du programme.</li>
        </ul>
        <p>Pass&eacute; les d&eacute;lais susvis&eacute;s, vos informations personnelles seront soit supprim&eacute;es en toute s&eacute;curit&eacute;, soit rendues anonymes afin de pouvoir &ecirc;tre utilis&eacute;es &agrave; des fins d'analyse.</p>
        <p><strong>Les donn&eacute;es personnelles des mineurs</strong></p>
        <p>La candidature et/ou participation et/ou contribution d'un mineur de moins de 18 ans &agrave; l&rsquo;un de nos programmes n'est permise que si elle fait l'objet d'une autorisation pr&eacute;alable des parents ou repr&eacute;sentants l&eacute;gaux. Le fait pour un mineur de pr&eacute;senter sa candidature implique qu'il a obtenu cette autorisation pr&eacute;alable.</p>
        <p>Nous nous r&eacute;servons le droit (i) de prendre les mesures appropri&eacute;es afin de d&eacute;terminer l&rsquo;&acirc;ge de tout candidat et/ou participant (ii) d'en demander la justification &eacute;crite &agrave; tout moment dans un d&eacute;lai de cinq (5) jours &agrave; compter de la demande.</p>
        <p>Si vous &ecirc;tes les parents ou gardiens d&rsquo;un mineur &acirc;g&eacute; de moins de 18 ans et que vous apprenez que votre enfant nous a fourni, sans votre consentement, des informations personnelles, veuillez nous contacter &agrave; l&rsquo;adresse indiqu&eacute;e dans la rubrique &laquo; Contacts &raquo; ci-dessous. Nous supprimerons ces informations de nos services dans un d&eacute;lai raisonnable.</p>
        <p>Lorsque vous souhaitez exercer vos droits en qualit&eacute; de repr&eacute;sentant l&eacute;gal d'un utilisateur mineur ou d'une personne majeure n'ayant pas la capacit&eacute; juridique, nous aurons &eacute;galement besoin d'une attestation sur l'honneur et de documents par lesquels vous pouvez justifier de votre qualit&eacute; de repr&eacute;sentant l&eacute;gal de l'utilisateur au titre duquel vous exercez des droits. Nous aurons &eacute;galement besoin d'une photocopie de votre pi&egrave;ce d'identit&eacute; et d'un justificatif de domicile datant de moins de 3 mois.</p>
        <p><strong>Vos droits</strong></p>
        <p>Vous avez un droit :</p>
        <ul>
            <li><strong>D&rsquo;acc&egrave;s &agrave; vos donn&eacute;es</strong>&nbsp;: vous pouvez obtenir des informations sur le traitement de vos donn&eacute;es personnelles et vous pouvez obtenir une copie des donn&eacute;es que nous traitons.</li>
            <li><strong>De rectification de vos donn&eacute;es</strong>&nbsp;: si vous estimez que vos donn&eacute;es personnelles sont incorrectes ou incompl&egrave;tes, vous pouvez demander que ces donn&eacute;es personnelles soient modifi&eacute;es.</li>
            <li><strong>D&rsquo;effacement de vos donn&eacute;es</strong>&nbsp;: vous pouvez demander que vos donn&eacute;es personnelles soient effac&eacute;es.</li>
            <li><strong>De limitation du traitement</strong>&nbsp;: vous pouvez demander la limitation du traitement de vos donn&eacute;es personnelles.</li>
            <li><strong>D&rsquo;opposition</strong>&nbsp;: vous pouvez vous opposer au traitement de vos donn&eacute;es personnelles lorsqu&rsquo;un motif sp&eacute;cifique &agrave; votre situation le justifie, dans les cas pr&eacute;vus par la loi ou d'autres textes r&eacute;glementaires.</li>
            <li><strong>De retrait de votre consentement</strong>&nbsp;: pour autant que vous ayez donn&eacute; votre consentement au traitement de donn&eacute;es personnelles, vous avez toujours le droit de retirer ce consentement, sans porter atteinte &agrave; la lic&eacute;it&eacute; du traitement fond&eacute; sur le consentement et effectu&eacute; avant le retrait de celui-ci. Cependant, si les donn&eacute;es sont trait&eacute;es sur une autre base l&eacute;gale, le traitement peut se poursuivre.</li>
            <li><strong>De portabilit&eacute; des donn&eacute;es</strong>&nbsp;: dans les cas pr&eacute;vus par la loi et lorsque cela est techniquement possible, vous avez le droit d&rsquo;obtenir les donn&eacute;es personnelles que vous nous avez fournies et de les faire transf&eacute;rer &agrave; un tiers.</li>
            <li><strong>De d&eacute;finir des directives relatives au sort de vos donn&eacute;es personnelles apr&egrave;s votre d&eacute;c&egrave;s</strong>&nbsp;: pour exercer ce droit, nous vous invitons &agrave; nous adresser une demande :
                <ul>
                    <li>ayant pour objet &laquo; Directives relatives au sort de mes donn&eacute;es personnelles apr&egrave;s mon d&eacute;c&egrave;s &raquo; ;</li>
                    <li>nous indiquer la(es) personne(s) pouvant agir aupr&egrave;s de nous dans l&rsquo;hypoth&egrave;se de votre d&eacute;c&egrave;s, ainsi que les instructions que vous souhaitez que nous respections.</li>
                </ul>
            </li>
        </ul>
        <p>Si vous souhaitez exercer l'un des droits susmentionn&eacute;s, vous pouvez nous contacter via l&rsquo;adresse indiqu&eacute;e dans la rubrique &laquo; Contacts &raquo; ci-dessous.</p>
        <p>Veuillez noter qu'il se peut que nous ne soyons pas toujours en mesure de r&eacute;pondre positivement &agrave; votre demande et ce, pour des raisons l&eacute;gales sp&eacute;cifiques qui vous seront notifi&eacute;es, le cas &eacute;ch&eacute;ant, au moment de votre demande. Par ailleurs, il se peut que l&rsquo;exercice de vos droits ait pour cons&eacute;quence de ne pas pouvoir contribuer &agrave; l&rsquo;un de nos programmes. Nous vous en aviserons le cas &eacute;ch&eacute;ant, au moment de votre demande.</p>
        <p><strong>Modifications apport&eacute;es &agrave; cette politique de confidentialit&eacute;</strong></p>
        <p>Nous pouvons toujours ajuster cette politique afin de tenir compte des changements dans la loi, en fonction des meilleures pratiques ou de nos pratiques concernant le traitement des donn&eacute;es personnelles. Si des modifications importantes sont apport&eacute;es, nous vous informerons par tout moyen &eacute;crit. La date de la mise &agrave; jour la plus r&eacute;cente sera indiqu&eacute;e en haut de cette page.</p>
        <p><strong>Contacts</strong></p>
        <p>Si vous avez des questions concernant la pr&eacute;sente Politique de confidentialit&eacute;, y compris toute demande relative &agrave; l&rsquo;exercice de vos droits, veuillez nous contacter &agrave; l&rsquo;adresse suivante :</p>
        <p>NoFIlter<br />&nbsp;34, rue du Stade<br />&nbsp;Hydra &ndash; Alger<br />&nbsp;Email : hello@nofilter.team</p>
        <p>Nous ferons de notre mieux pour r&eacute;soudre toute r&eacute;clamation.</p>
    </>
}